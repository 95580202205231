<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="流水号/销售订单/收货信息" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.warehouses" placeholder="请选择出货仓库" filterable multiple collapse-tags>
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.expresses" placeholder="请选择快递" filterable multiple collapse-tags>
						<el-option v-for="(express, s) in expresses" :key="s" :label="express" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择状态" filterable multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDeliveries(params)">查询</el-button>
					<el-button type="primary" :disabled="!$utils.create($api.URI_MATERIALS_DELIVERIES)" @click="$refs['delivery_dialog'].open()">添加配送</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="deliveries" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="创建人" prop="employee.name" min-width="100"></el-table-column>
				<el-table-column label="出货仓库" prop="warehouse.name" min-width="80"></el-table-column>
				<el-table-column label="流水单号" min-width="230">
					<template slot-scope="scope">
						<p>配送：<el-link type="primary" :disabled="scope.row.status != 1" @click="$refs['delivery_dialog'].open({ ...scope.row, order_no: scope.row.order ? scope.row.order.no : '' })">{{scope.row.no}}</el-link></p>
						<p v-if="scope.row.order">
							销售：<el-link type="primary" @click="$refs['show_dialog'].open(scope.row.order)">{{scope.row.order.no}}</el-link>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="收货信息" min-width="220">
					<template slot-scope="scope">
						<p>{{scope.row.name}}，{{scope.row.mobile}}</p>
						<p>{{scope.row.address}}</p>
						<p>{{expresses[scope.row.company_code] || '未知配送'}}</p>
					</template>
				</el-table-column>
				<el-table-column label="物料清单" min-width="80">
					<el-link slot-scope="scope" type="primary" @click="delivery = { ...scope.row}; getSheets({material_delivery_id: scope.row.id, perPage: 100}); sheets_drawer = true;">{{scope.row.delivery_materials_count}} 款</el-link>
				</el-table-column>
				<el-table-column label="状态" min-width="70">
					<template slot-scope="scope">
						<el-button type="text" @click="onStatus(scope.row)" v-if="scope.row.status == 1 || scope.row.status == 3">{{statuses[scope.row.status]}}</el-button>
						<span v-else>{{statuses[scope.row.status]}}</span>
					</template>
				</el-table-column>
				<template v-if="theme.width >= 1420">
					<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
					<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
				</template>
				<el-table-column label="操作" width="60">
					<template slot-scope="scope">
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_MATERIALS_DELIVERIES)" v-if="!scope.row.deleted_at">删除</el-button>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_MATERIALS_DELIVERIES)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getDeliveries({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getDeliveries({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<!-- 物料清单 -->
		<el-drawer :size="theme.width <= 640 ? '100%' : '640px'" :title="delivery.no + ' 配送物料清单'" :visible.sync="sheets_drawer">
			<div style="padding: 0 10px;">
				<div style="line-height: 1.5; padding: 10px; color: #666; font-size: 14px; background-color: #f9f9f9;">
					<p v-if="delivery.order">销售单号：{{delivery.order.no}}</p>
					<p>收货信息：{{delivery.name}}，{{delivery.mobile}}，{{delivery.address}}</p>
					<p v-if="delivery.remarks">备注说明：{{delivery.remarks}}</p>
				</div>
				<el-table :data="sheets" :size="theme.size">
					<el-table-column label="编码" min-width="140" prop="in_code" sortable="custom"></el-table-column>
					<el-table-column label="名称" min-width="140" prop="name"></el-table-column>
					<el-table-column label="数量" min-width="110" v-if="delivery.status == 1 || delivery.status == 3">
						<el-input-number slot-scope="scope" size="mini" v-model="scope.row.amount" @change="(e) => { submitSheets({id: scope.row.id, amount: e}, () => { getSheets({material_inventory_id: inventory.id,  perPage: 100}); }); }" :min="1" :max="scope.row.max_amount || 0" label="数量"></el-input-number>
					</el-table-column>
					<el-table-column label="数量" min-width="110" prop="amount" v-else></el-table-column>
					<el-table-column label="操作" width="60" v-if="delivery.status == 1 || delivery.status == 3">
						<template slot-scope="scope">
							<el-popconfirm title="确定删除这条记录吗？" @confirm="delSheet(scope.row)">
								<el-button slot="reference" type="text">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<el-form ref="create_sheet" style="margin-top: 10px;" :inline="true" :model="sheet" :size="theme.size" v-if="delivery.status == 1 || delivery.status == 3">
					<el-form-item prop="no" :rules="[{ required: true, message: '请输入物料编码', trigger: 'blur' }]">
						<el-input v-model="sheet.no" placeholder="请输入物料编码" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_sheet')">添加物料</el-button>
						<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importSupplies" :action="$api.URI_UPLOADS">
							<el-button type="primary" plain>批量导入</el-button>
						</el-upload>
						<el-button type="text" @click="downloadTemp">下载模板</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
		<order-show ref="show_dialog"></order-show>
		<show-delivery ref="delivery_dialog" :warehouses="warehouses" :expresses="expresses" @refresh="getDeliveries(params)"></show-delivery>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import showDelivery from './show';
	import mainTable from '@/components/main-table';
	import orderShow from '@/pages/oms/orders/show';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		components: {
			mainTable,
			orderShow,
			showDelivery
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			table_temp () {
				const thead = ['编码', '数量'];
				const filter = ['material_no', 'amount'];
				return {
					data: [],
					sheet: '物料明细',
					thead,
					filter
				};
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getDeliveries(this.params);
			},
			async delSheet (r) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_DELIVERIES_SHEETS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getSheets({material_delivery_id: this.delivery.id, perPage: 100});
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.no+'】物料配送吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_DELIVERIES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getDeliveries(this.params);
					this.$message.success(msg);
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.no+'】物料配送吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_DELIVERIES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getDeliveries(this.params);
					this.$message.success(msg);
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_sheet':
							this.submitSheets({
								material_delivery_id: this.delivery.id,
								material_no: this.sheet.no,
							}, () => {
								this.getSheets({material_delivery_id: this.delivery.id,  perPage: 100});
								this.sheet = {};
							});
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			async submitSheets (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_DELIVERIES_SHEETS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			async getDeliveries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_DELIVERIES, {params, headers: {loading}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.deliveries = result.data;
				this.warehouses = result.warehouses;
				this.expresses = result.expresses;
				this.statuses = result.statuses;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getSheets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_DELIVERIES_SHEETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.sheets = result.data;
			},
			onStatus (r) {
				const { id, no, delivery_materials_count, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!delivery_materials_count) return this.$message.error('物料清单为0无法提交审核！');
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">'+ delivery_materials_count + ' 款物料</b>', '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\MaterialDelivery'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.getSuppliers(this.params);
								}
							});
						});
					break;

					default:
						this.open(r);
					break;
				}
			},
			importSupplies ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入配送物料...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { material_no, amount } = data[i];
							if (!material_no) {
								data[i].status = "提交物料编码不能为空";
								continue;
							}
							if (!amount) {
								data[i].status = "提供数量";
								continue;
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_MATERIALS_DELIVERIES_SHEETS, {...data[i], material_delivery_id: this.delivery.id});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "配送物料导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							this.getSheets({material_delivery_id: this.delivery.id,  perPage: 100});
							setTimeout(() => {
								notify.close();
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				ad_export_json_to_excel_multi_sheet([this.table_temp], '物料导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				warehouses: [],
				deliveries: [],
				expresses: [],
				statuses: [],
				sheets: [],
				delivery: {},
				sheet: {},
				sheets_drawer: false,
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getDeliveries(this.params, true);
		}
	};
</script>